<script setup lang="ts">
const { isMobile } = useBreakpoint()
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { isNewHeader } = useHeaderV2()

const imageCover = computed(() =>
  isMobile.value
    ? 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2025/cover-summer-2025-mobile.webp'
    : 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2025/cover-summer-2025-desktop.webp',
)
const s3Url = computed(() =>
  isMobile.value
    ? 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2025/video-summer-2025-mobile.mp4'
    : 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2025/video-summer-2025-desktop.mp4',
)

const trackWhereToLeave = () => {
  trackEvent({
    category: 'General',
    event: 'typeform_homepage_click',
  })
}

const containerSearch = ref<HTMLDivElement | null>(null)
const fixed = ref(false)
const SIZE_TOP_FIXED = 24

const onScroll = () => {
  const position = containerSearch.value?.getBoundingClientRect()?.top || 0

  fixed.value = position - SIZE_TOP_FIXED < 0
}

onMounted(() => {
  if (isNewHeader.value) {
    document.addEventListener('scroll', onScroll)
  }
})

onUnmounted(() => {
  if (isNewHeader.value) {
    document.removeEventListener('scroll', onScroll)
  }
})
</script>

<template>
  <BaseVideoBg
    class="mb-[200px] flex h-[458px] items-center bg-gray-700/30 text-center md:mb-0"
    :img="imageCover"
    :source="s3Url"
  >
    <div class="home-bg-video__overlay" />
    <BaseContainer class="pt-[510px] md:pt-0">
      <BaseRow class="justify-center">
        <BaseCol md="20" lg="14">
          <h2
            class="mb-2 text-5xl leading-9 text-white md:text-[28px] md:leading-7"
            data-cy="homepage-title"
          >
            {{ $t('home.headline') }}
          </h2>
          <p
            class="mb-0 hidden text-md leading-5 text-white md:block md:text-base"
            data-cy="homepage-subtitle"
          >
            {{ $t('home.headline_subtitle') }}
          </p>

          <SearchInputV2MobileContainer v-if="isMobile" />
          <div
            v-else
            ref="containerSearch"
            class="my-4 flex h-[52px] justify-center"
          >
            <SearchInputV2Container
              :class="{ 'fixed top-4 z-modal': fixed }"
              large
              next-flow
            />
          </div>

          <BaseButton
            :to="localePath({ name: 'typeform' })"
            target="_blank"
            class="!text-gray-700 md:!text-white"
            variant="link"
            @mousedown="trackWhereToLeave"
          >
            {{ $t('home.where_to') }}
          </BaseButton>
        </BaseCol>
      </BaseRow>
    </BaseContainer>
  </BaseVideoBg>
</template>
