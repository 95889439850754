<script setup lang="ts">
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { locale, t } = useI18n()

const searchPath = localePath({
  name: 'search',
  query: {
    facetFilter: `searchable_tags.1.${locale.value}: Chalet`,
  },
})
const hotDealsPath = computed(() => {
  switch (locale.value) {
    case 'en':
      return '/en/luxury-villas-rentals/luxury-ski-chalets'
    case 'fr':
      return '/fr/location-villas-luxe/chalets-de-luxe'
    default:
      // This should never happen
      return searchPath
  }
})

const onClickHotDeals = () =>
  trackEvent({
    event: 'homepage_favourites_click',
    suggestion: 'house',
  })
</script>

<template>
  <BaseContainer class="bg-gray-100 py-8 lg:max-w-full">
    <BaseRow class="lg:items-center lg:justify-center">
      <BaseCol cols="24" md="12" sm="24">
        <BaseSectionContent :title="t('title')">
          <template #header>
            <p class="mb-8 max-w-screen-sm">
              {{ t('text') }}
            </p>
            <BaseButton
              class="items-center text-lg"
              color="secondary"
              size="xl"
              :to="hotDealsPath"
              @click.prevent="onClickHotDeals"
            >
              {{ t('cta') }}
            </BaseButton>
          </template>
        </BaseSectionContent>
      </BaseCol>
      <BaseCol cols="24" md="12" sm="24" class="pt-8 sm:pt-0">
        <BaseNuxtImg
          class="lg:p-20"
          src="production/assets/images/homepage/winter-2025.webp"
          width="547"
          height="365"
          alt="Last minute Hot Deals"
        />
      </BaseCol>
    </BaseRow>
  </BaseContainer>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Easy skiing",
    "text": "Surprise! Some of our most beautiful chalets are still available for February. Quick, quick, ski into spring!",
    "cta": "Last minute chalets"
  },
  "fr": {
    "title": "Ski doux",
    "text": "Surprise : certains de nos plus beaux chalets sont encore disponibles en février. Vite, vite, skiez vers le printemps !",
    "cta": "Chalets last minute"
  }
}
</i18n>
